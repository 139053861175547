import { Headline } from "@components/cms/headline/Headline";
import { PageSeo } from "@components/seo/PageSeo";
import { getSiteSettings } from "@config/site/site-api-settings";
import { getPage } from "@contentful-api/queries/pages/pageService";
import type {
    IModuleHeadline,
    IPageHomepage,
    ISiteSettings,
} from "@contentful-api/types/contentful";
import type { NextPageWithLayout } from "@interfaces/PageInterfaces";
import { getPagePathByLocale, homePage } from "@lib/constants/pagePaths";
import { COMMON_NEXT_CACHE_TTL } from "@lib/constants/revalidation";
import { ModulesRenderer } from "@lib/contentful/modules-renderer/ModulesRenderer";
import { PageTypes } from "@lib/enums/PageTypes";
import { Logger, LogTag, ServiceType } from "@lib/monitoring/logger";
import { getCompleteSlug } from "@lib/utils/getCompleteSlug";
import { updatePageHotspotsModules } from "@lib/utils/hotspotsHelper";
import { getDefaultLayout } from "@lib/utils/layoutUtility";
import { Box } from "@ui/components/layout/box/Box";
import { deserialize, serialize } from "@ungap/structured-clone";
import type { GetStaticPropsContext, PreviewData } from "next";
import type { ParsedUrlQuery } from "querystring";

type HomePageProps = Awaited<ReturnType<typeof getStaticProps>>["props"];

const HomePage: NextPageWithLayout<HomePageProps> = ({
    serializedPage,
    pathName,
    serializedSiteSettings,
}) => {
    const page: IPageHomepage = deserialize(serializedPage);
    const siteSettings: ISiteSettings = deserialize(serializedSiteSettings);
    const authorizationInvitation = siteSettings?.fields?.authorizationInvitation;
    const pageModules = page?.fields?.modules;
    const seoMetadata = page?.fields?.seoMetadata;
    const pageHeadingTitle = page?.fields?.title;
    const pageHeadingCTAs = page?.fields?.callToActions;
    const description = page?.fields?.desc;

    return (
        <Box sx={{ mt: ["-67px", "-72px", "-82px", "-82px"] }} data-testid="Homepage">
            {seoMetadata && <PageSeo seoModule={seoMetadata} pathName={pathName} />}
            {description ? (
                <Headline
                    module={
                        {
                            fields: {
                                title: pageHeadingTitle,
                                desc: description,
                                callToActions: pageHeadingCTAs,
                            },
                        } as IModuleHeadline
                    }
                    isModule={false}
                />
            ) : null}
            <main>
                <ModulesRenderer
                    modules={pageModules}
                    authorizationInvitation={authorizationInvitation}
                />
            </main>
        </Box>
    );
};

export default HomePage;

HomePage.getLayout = getDefaultLayout;

export const getStaticProps = async (
    context: GetStaticPropsContext<ParsedUrlQuery, PreviewData>
) => {
    const startTime = performance.now();
    const { locale, params } = context;

    const contentfulStartTime = performance.now();
    const { data, error } = await getPage({
        slug: params?.slug,
        locale,
        basePath: homePage,
        localeTagId: getSiteSettings.contentfulLocaleTagId,
        textCollection: ["shared", "plp"],
    });
    Logger.info(ServiceType.CONTENTFUL, "HomePage Contentful Query", {
        tag: LogTag.PERFORMANCE,
        duration: performance.now() - contentfulStartTime,
        pageType: PageTypes.HOME,
    });

    if (error) throw error;

    if (!data?.pagePath) {
        return {
            notFound: true,
            revalidate: COMMON_NEXT_CACHE_TTL,
        };
    }

    const siteSettingsContent = data?.siteSettings.siteSettings;
    const pathName = getCompleteSlug(params?.slug, getPagePathByLocale(homePage, locale));

    const hotspotStartTime = performance.now();
    const pageData = await updatePageHotspotsModules(
        data?.pagePath?.fields?.page as IPageHomepage,
        locale
    );
    Logger.info(ServiceType.ALGOLIA, "HomePage Hotspot Query", {
        tag: LogTag.PERFORMANCE,
        duration: performance.now() - hotspotStartTime,
        pageType: PageTypes.HOME,
    });

    // We need to serialize the data so that Next doesn't explode trying to stringify circular references
    const serializedPage = serialize(pageData);
    const serializedSiteSettings = serialize(siteSettingsContent);
    const serializedSharedMicrocopySet = serialize(data?.siteSettings["shared"]);
    const serializedPageMicrocopySet = serialize(data?.siteSettings["plp"]);

    Logger.info(ServiceType.NEXT_API, "HomePage Total Time", {
        tag: LogTag.PERFORMANCE,
        duration: performance.now() - startTime,
        pageType: PageTypes.HOME,
    });

    return {
        props: {
            serializedSiteSettings,
            serializedPage,
            pathName,
            pageType: PageTypes.HOME,
            serializedSharedMicrocopySet,
            serializedPageMicrocopySet,
        },
        revalidate: 300,
    };
};
